import './Quote.css';

const Quote = props => {
    return (
        <div className="quote" style={{left: props.position.left, top: props.position.top}}>
        <p className="quoteText">
            {props.values.text}
        </p>
        <p className="person">
            {props.values.person}
        </p>
        </div>
    )
};

export default Quote;