import { useState } from 'react';
import './App.css';
import quotes from './assets/quotes.json';
import Quote from './components/Quote/Quote';

function App() {

  const [currentQuoteObj, updateQuoteObj] = useState({});
  const [clientPosition, updateClientPosition] = useState({});

  const handleClick = e => {

    let randomPersonIndex = Math.floor(Math.random() * quotes.length);
    let randomPerson = Object.keys(quotes[randomPersonIndex]);
    let randomQuote = quotes[randomPersonIndex][randomPerson][Math.floor(Math.random() * quotes[randomPersonIndex][randomPerson].length)];

    updateQuoteObj(prevState => ({
      ...prevState,
      "person": randomPerson[0],
      "text": randomQuote
    }));

    updateClientPosition(prevState => ({
      ...prevState,
      "left": (e.clientX - 10) + "px",
      "top": (e.clientY - 40) + "px"
    }));
  };

  return (


    <div className="App">
      <header className="App-header">
      </header>
      <body>
        <div id="main" onClick={handleClick}>
          <div class="wrapper">
            <div class="typing-demo">
              'This is a playground.'
            </div>
          </div>
          <Quote 
          values={currentQuoteObj} 
          position={clientPosition}
          />
        </div>
      </body>
    </div>
  );
}


export default App;
